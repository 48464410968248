<template>
  <v-card flat class="quantity-field">
    <v-container class="pa-0">
      <v-row no-gutters class="main-row flex-nowrap" justify="space-between">
        <v-col cols="7">
          <p class="mb-0 quantity-title">
            {{ title }}
            <template v-if="subTitle">
              <span class="quantity-title-age">
                (age {{ parseAge(subTitle) }})
              </span>
            </template>
          </p>
          <p class="mb-0 quantity-subtitle">
            {{ valueSupportText }}
          </p>
        </v-col>
        <v-col cols="5">
          <v-row
            class="flex-nowrap"
            no-gutters
            justify="end"
            id="quantityField"
          >
            <v-btn
              large
              class="pa-0 ma-0 button-col decrement-button"
              @click="decrementValue()"
              icon
              :disabled="!canDecrementValue"
              aria-label="decrement-button"
              title="decrement-button"
            >
              <v-icon aria-hidden="true">mdi-minus</v-icon>
            </v-btn>
            <v-text-field
              class="
                size16-weight600
                num-val
                pa-0
                input-col
                quantity-field-input
              "
              v-model.number="currentValue"
              @blur="validateValue()"
              @click="clearInput()"
              maxlength="2"
              @keypress="onKeyPress($event)"
              dense
              solo
              flat
              :disabled="!canEditTextField"
              label=""
              aria-labelledby="quantityField"
              aria-selected="false"
              tabindex="0"
            />
            <v-btn
              small
              class="pa-0 button-col increment-button"
              @click="incrementValue()"
              icon
              data-cy="increment-pass"
              :disabled="!canIncrementValue"
              aria-label="increment-button"
              title="increment-button"
            >
              <v-icon aria-hidden="true">mdi-plus</v-icon>
            </v-btn>
          </v-row>
          <v-row no-gutters justify="end">
            <template v-if="currentValue < min && passError">
              <v-col cols="auto" class="error-message">
                Minimum {{ min }}
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'QuantityFieldWidget',
  props: {
    title: String,
    subTitle: String,
    passError: {
      type: Boolean,
      default: false,
    },
    value: { type: Number, default: 0 },
    valueSupportText: String,
    max: { type: Number, default: Number.MAX_VALUE },
    min: { type: Number, default: 0 },
  },

  data() {
    return {
      currentValue: null,
    };
  },

  methods: {
    incrementValue() {
      this.setCurrentValueIfUnset();
      this.currentValue = parseInt(this.currentValue, 10) + 1;
    },

    decrementValue() {
      this.setCurrentValueIfUnset();
      if (this.currentValue > 0 && this.currentValue) {
        this.currentValue = parseInt(this.currentValue, 10) - 1;
      }
    },

    setCurrentValueIfUnset() {
      if (isNaN(parseFloat(this.currentValue)) || this.currentValue < 0) {
        this.currentValue = 0;
      }
    },

    validateValue() {
      this.setCurrentValueIfUnset();
      this.validateMax();
      this.validateMin();
    },

    validateMax() {
      if (!!this.max && this.currentValue > this.max) {
        this.currentValue = this.max;
      }
    },

    validateMin() {
      if (!!this.min && this.currentValue < this.min) {
        this.currentValue = this.min;
      }
    },

    clearInput() {
      this.currentValue = null;
    },

    onKeyPress(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      //Check that the key pressed is numerical
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else {
        this.setCurrentValueIfUnset();
        //Check that the key pressed is enter or return key
        if (charCode == 13) {
          this.validateValue();
        }
        this.currentValue = parseInt(this.currentValue, 10);
        return true;
      }
    },

    parseAge(string) {
      let array = string
        .split(/[^\d\.]+/)
        .map(parseFloat)
        .filter((v) => v === v);
      if (array && array.length > 1) {
        return `${array[0]}-${array[1]}`;
      } else {
        return '';
      }
    },
  },

  watch: {
    currentValue() {
      this.$emit('input', this.currentValue);
    },

    value: {
      immediate: true,
      handler(value) {
        this.currentValue = value;
      },
    },

    max(max) {
      if (max === 0) this.currentValue = 0;
    },
  },

  computed: {
    canIncrementValue() {
      return isNaN(this.max) || this.currentValue + 1 <= this.max;
    },

    canDecrementValue() {
      if (this.min === 1) {
        return isNaN(this.min) || this.currentValue >= this.min;
      } else {
        return isNaN(this.min) || this.currentValue >= this.min + 1;
      }
    },

    canEditTextField() {
      return this.canIncrementValue || this.canDecrementValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-row {
  max-height: 66px;
}

.error-message {
  color: #ff4d4f;
  @include font-size(14, 150);
}

::v-deep .quantity {
  &-field {
    &-input {
      .v-input__slot {
        padding: 0 !important;
      }
    }
  }

  &-title {
    color: var(--v-grey9-base);
    letter-spacing: 0.25px;
    @include font-size(16, 150, 600);

    &-age {
      @include font-size(16, 150, 400);
    }
  }

  &-subtitle {
    padding: 5px 0 0;
    color: var(--v-grey7-base);
    letter-spacing: 0.1px;
    @include font-size(14, 150, 400);
  }
}
.decrement-button,
.increment-button {
  margin: auto 0 !important;
  color: var(--v-grey7-base);
}
.button-col {
  max-height: 24px;
  max-width: 24px;
  z-index: 1;
}
.input-col {
  max-width: 60px;
}
::v-deep.v-input.num-val {
  max-height: 30px;
  .v-input__control .v-input__slot {
    max-height: 20px;
    padding-bottom: 0px;
    input {
      text-align: center;
    }
  }
  .v-input__control {
    max-height: 20px;
    min-height: 20px;
  }
}
</style>
