var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-wrapper"},[(_vm.step.name == _vm.$t('product.steps.names.date'))?[_c('svg',{attrs:{"width":"18","height":"17","viewBox":"0 0 18 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"\n          M5.66667 4.33333V1V4.33333ZM12.3333 4.33333V1V4.33333ZM4.83333 7.66667H13.1667H4.83333ZM3.16667\n          16H14.8333C15.2754 16 15.6993 15.8244 16.0118 15.5118C16.3244 15.1993 16.5 14.7754 16.5 14.3333V4.33333C16.5\n          3.89131 16.3244 3.46738 16.0118 3.15482C15.6993 2.84226 15.2754 2.66667 14.8333 2.66667H3.16667C2.72464\n          2.66667 2.30072 2.84226 1.98816 3.15482C1.67559 3.46738 1.5 3.89131 1.5 4.33333V14.3333C1.5 14.7754\n          1.67559 15.1993 1.98816 15.5118C2.30072 15.8244 2.72464 16 3.16667 16Z\n        ","stroke":_vm.isActiveOrDone
            ? _vm.$vuetify.theme.themes.light.primary.base
            : _vm.$vuetify.theme.themes.light.grey7,"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]:_vm._e(),(_vm.step.name == _vm.$t('product.steps.names.options'))?[_c('svg',{attrs:{"width":"18","height":"15","viewBox":"0 0 18 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"\n          M11.5 1.66699V3.33366V1.66699ZM11.5 6.66699V8.33366V6.66699ZM11.5 11.667V13.3337V11.667ZM3.16667\n          1.66699C2.72464 1.66699 2.30072 1.84259 1.98816 2.15515C1.67559 2.46771 1.5 2.89163 1.5\n          3.33366V5.83366C1.94203 5.83366 2.36595 6.00925 2.67851 6.32182C2.99107 6.63438 3.16667 7.0583\n          3.16667 7.50033C3.16667 7.94235 2.99107 8.36628 2.67851 8.67884C2.36595 8.9914 1.94203 9.16699\n          1.5 9.16699V11.667C1.5 12.109 1.67559 12.5329 1.98816 12.8455C2.30072 13.1581 2.72464 13.3337\n          3.16667 13.3337H14.8333C15.2754 13.3337 15.6993 13.1581 16.0118 12.8455C16.3244 12.5329 16.5\n          12.109 16.5 11.667V9.16699C16.058 9.16699 15.634 8.9914 15.3215 8.67884C15.0089 8.36628 14.8333\n          7.94235 14.8333 7.50033C14.8333 7.0583 15.0089 6.63438 15.3215 6.32182C15.634 6.00925 16.058\n          5.83366 16.5 5.83366V3.33366C16.5 2.89163 16.3244 2.46771 16.0118 2.15515C15.6993 1.84259 15.2754\n          1.66699 14.8333 1.66699H3.16667Z\n        ","stroke":_vm.isActiveOrDone
            ? _vm.$vuetify.theme.themes.light.primary.base
            : _vm.$vuetify.theme.themes.light.grey7,"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]:_vm._e(),(_vm.step.name == _vm.$t('product.steps.names.travelers'))?[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"\n          M9 2.62833C9.44787 2.12059 10.0397 1.76126 10.6968 1.59816C11.354 1.43507 12.0451\n          1.47594 12.6785 1.71533C13.3118 1.95473 13.8572 2.38131 14.2421 2.9383C14.627 3.49529\n          14.8332 4.15629 14.8332 4.83333C14.8332 5.51038 14.627 6.17138 14.2421 6.72837C13.8572\n          7.28536 13.3118 7.71193 12.6785 7.95133C12.0451 8.19073 11.354 8.2316 10.6968 8.06851C10.0397\n          7.90541 9.44787 7.54608 9 7.03833M11.5 16.5H1.5V15.6667C1.5 14.3406 2.02678 13.0688 2.96447\n          12.1311C3.90215 11.1935 5.17392 10.6667 6.5 10.6667C7.82608 10.6667 9.09785 11.1935 10.0355\n          12.1311C10.9732 13.0688 11.5 14.3406 11.5 15.6667V16.5ZM11.5 16.5H16.5V15.6667C16.5001 14.7889\n          16.2692 13.9266 15.8304 13.1664C15.3916 12.4062 14.7604 11.7749 14.0003 11.336C13.2402 10.8971\n          12.3779 10.666 11.5002 10.666C10.6224 10.6659 9.76015 10.897 9 11.3358M9.83333 4.83333C9.83333\n          5.71739 9.48214 6.56524 8.85702 7.19036C8.2319 7.81548 7.38405 8.16667 6.5 8.16667C5.61594\n          8.16667 4.7681 7.81548 4.14298 7.19036C3.51786 6.56524 3.16667 5.71739 3.16667 4.83333C3.16667\n          3.94928 3.51786 3.10143 4.14298 2.47631C4.7681 1.85119 5.61594 1.5 6.5 1.5C7.38405 1.5 8.2319\n          1.85119 8.85702 2.47631C9.48214 3.10143 9.83333 3.94928 9.83333 4.83333Z\n        ","stroke":_vm.isActiveOrDone
            ? _vm.$vuetify.theme.themes.light.primary.base
            : _vm.$vuetify.theme.themes.light.grey7,"stroke-width":"1.6","stroke-linecap":"round","stroke-linejoin":"round"}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }