<template>
  <v-container fluid class="pa-0 ma-0 align-center">
    <div class="d-flex flex-wrap">
      <v-row v-for="(step, i) in steps" :key="i" class="ma-0 steps">
        <v-col cols="12" class="pa-0 d-flex">
          <v-row no-gutters class="pa-0 d-flex align-center">
            <ProgressIndicatorDetails :step="step" />
            <span
              class="pl-2 size14-weight400"
              :class="{
                'size14-weight700':
                  step.status === 'ACTIVE' || step.status === 'DONE',
              }"
            >
              {{ step.name }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ProgressIndicatorDetails from './ProgressIndicatorDetails';
export default {
  name: 'ProgressIndicatorDetailsWrapper',
  components: { ProgressIndicatorDetails },
  props: {
    steps: {
      default: function() {
        return [
          {
            name: this.$t('product.steps.names.date'),
            status: 'ACTIVE',
          },
          {
            name: this.$t('product.steps.names.options'),
            status: 'PENDING',
          },
          {
            name: this.$t('product.steps.names.travelers'),
            status: 'PENDING',
          },
        ];
      },
      type: Array,
    },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    currentStep() {
      let current = 0;
      for (let step of this.steps) {
        if (step.status === 'DONE' || step.status === 'ACTIVE') {
          current++;
        } else {
          break;
        }
      }
      return current;
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  position: relative;
  flex: 0 0 auto;
  padding: 5px 10px 5px 16px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 10px;
    height: 1px;
    background: #b8c1d1;
  }

  &:first-child {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  &:last-child {
    padding-right: 0;
  }
}
.seperator {
  width: 20px;
  height: 2px;
  background: #e8e6e1;
  border-radius: 2px;
}
.seperator-mobile {
  width: 20px;
  height: 2px;
  border-radius: 2px;
  background: #e8e6e1;
}
.font-mobile {
  font-size: 12px;
}
.step-mobile {
  padding-bottom: 6px;
}
</style>
